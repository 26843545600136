/* eslint-disable max-lines */

import { MoneyV2 } from '@ts/shopify-storefront-api';
import { FRAME_COLORS, RX_TYPE_ID, translate } from '..';

export enum RX_TYPE {
	NON_RX = 'Non-RX',
	SINGLE_VISION = 'Single Vision',
	READERS = 'Readers',
	PROGRESSIVE = 'Progressive',
	PREMIUM_PROGRESSIVE = 'Premium Progressive',
}

export const getRxType = (locale: string, rxType: RX_TYPE) => {
	const rxTypeOptions: Record<RX_TYPE, string> = {
		[RX_TYPE.NON_RX]: translate('non-rx', locale),
		[RX_TYPE.SINGLE_VISION]: translate('single-vision', locale),
		[RX_TYPE.READERS]: translate('readers', locale),
		[RX_TYPE.PROGRESSIVE]: translate('progressive', locale),
		[RX_TYPE.PREMIUM_PROGRESSIVE]: translate('premium progressive', locale),
	};
	return rxTypeOptions[rxType];
};

export const RX_TYPE_HANDLE_MAP: Record<RX_TYPE, RX_TYPE_ID> = {
	[RX_TYPE.NON_RX]: RX_TYPE_ID.NON_PRESCRIPTION,
	[RX_TYPE.SINGLE_VISION]: RX_TYPE_ID.SINGLE_VISION,
	[RX_TYPE.READERS]: RX_TYPE_ID.READERS,
	[RX_TYPE.PROGRESSIVE]: RX_TYPE_ID.PROGRESSIVES,
	[RX_TYPE.PREMIUM_PROGRESSIVE]: RX_TYPE_ID.PREMIUM_PROGRESSIVE,
};

export type BASE_FRAME_RX_TYPE_VARIANTS = `${RX_TYPE}`;

export type BASE_FRAME_LENS_VARIANTS =
	| 'None'
	| 'No Add-ons'
	| 'Blue Light'
	| 'Light Responsive'
	| 'Premium Plus'
	| 'Blue Light + Light Responsive'
	| 'Blue Light + Premium Plus'
	| 'Light Responsive + Premium Plus'
	| 'Blue Light + Light Responsive + Premium Plus'
	| 'Sun - Black'
	| 'Sun - Brown'
	| 'Sun - Green'
	| 'Sun - Silver Reflective'
	| 'Sun - Pink Reflective'
	| 'Sun - Blue Reflective'
	| 'Sun - Green Reflective'
	| 'Basic'
	| 'Blue Light + Basic'
	| 'Light Responsive + Basic';

export enum BASE_FRAME_LENS_OPTIONS {
	BLUE_LIGHT = 'Blue Light',
	LIGHT_RESPONSIVE = 'Light Responsive',
	PREMIUM_PLUS = 'Premium Plus',
	CR39 = 'Basic',
	NONE = 'None',
	SUN_BLACK = 'Sun - Black',
	SUN_BROWN = 'Sun - Brown',
	SUN_GREEN = 'Sun - Green',
	SUN_SILVER_REFLECTIVE = 'Sun - Silver Reflective',
	SUN_PINK_REFLECTIVE = 'Sun - Pink Reflective',
	SUN_BLUE_REFLECTIVE = 'Sun - Blue Reflective',
	SUN_GREEN_REFLECTIVE = 'Sun - Green Reflective',
}

export const getBaseFrameLensOptions = (locale: string, baseFrameLensOption: BASE_FRAME_LENS_OPTIONS) => {
	const baseFrameLensOptions: Record<BASE_FRAME_LENS_OPTIONS, string> = {
		[BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT]: translate('blue-light', locale),
		[BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE]: translate('light-responsive', locale),
		[BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS]: translate('premium-plus', locale),
		[BASE_FRAME_LENS_OPTIONS.CR39]: translate('basic', locale),
		[BASE_FRAME_LENS_OPTIONS.NONE]: translate('none', locale),
		[BASE_FRAME_LENS_OPTIONS.SUN_BLACK]: translate('sun-black', locale),
		[BASE_FRAME_LENS_OPTIONS.SUN_BROWN]: translate('sun-brown', locale),
		[BASE_FRAME_LENS_OPTIONS.SUN_GREEN]: translate('sun-green', locale),
		[BASE_FRAME_LENS_OPTIONS.SUN_SILVER_REFLECTIVE]: translate('sun-silver-reflective', locale),
		[BASE_FRAME_LENS_OPTIONS.SUN_PINK_REFLECTIVE]: translate('sun-pink-reflective', locale),
		[BASE_FRAME_LENS_OPTIONS.SUN_BLUE_REFLECTIVE]: translate('sun-blue-reflective', locale),
		[BASE_FRAME_LENS_OPTIONS.SUN_GREEN_REFLECTIVE]: translate('sun-green-reflective', locale),
	};
	return baseFrameLensOptions[baseFrameLensOption];
};

export const RX_LENS_OPTIONS: Array<BASE_FRAME_LENS_OPTIONS> = [
	BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT,
	BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE,
	BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS,
];
export const NON_RX_LENS_OPTIONS: Array<BASE_FRAME_LENS_OPTIONS> = [
	BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT,
	BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE,
];

export type BASE_FRAME_LENS_OPTION_VARIANTS = `${BASE_FRAME_LENS_OPTIONS}`;

/** Regex that matches everything in a string up to the second dash, "-".
 *  This is used to extract the color, shape and rx type from a Base Frame SKU
 */
export const BASE_REGEX = new RegExp(/([^-]+-[^-]+).*/);
export const BASE_NO_PRESCRIPTION_REGEX = new RegExp(/^\d{4}_\d{3}_ODF/);

export type BaseVariantPriceDictionary = Record<`${FRAME_COLORS} / ${RX_TYPE} / ${BASE_FRAME_LENS_VARIANTS}`, MoneyV2>;
