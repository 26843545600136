import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useCurrentRefinements, useHits, usePagination, useRefinementList, useSearchBox } from 'react-instantsearch';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BASE_FRAME_NAMES, LARGE_WIDTH } from '@utils/constants';
import { useActiveFrame, useIsMobile } from '@utils/hooks';
import { useCollectionsQuery } from '@utils/react-query/collections/useCollectionsQuery';
import { getAlgoliaPreSelectedRefinementsBuildFlow, LOCALE_DICT, validateQueryBaseFrame } from '..';
import { useBFContext } from './BuildFlow';

const FilterContext = createContext(null);

export const FilterProvider = ({
	collectionList,
	frameShapeOverride = undefined,
	isSunglassesRoute = false,
	type,
	children,
}: {
	collectionList: string;
	frameShapeOverride?: (typeof BASE_FRAME_NAMES)[number];
	isSunglassesRoute?: boolean;
	type: 'all-tops' | 'buildflow' | 'search' | 'bf-all-tops' | 'eyeglasses' | 'sunglasses';
	children: ReactNode;
}) => {
	const { setChooseTopsFilter, setChooseTopsHandler } = useBFContext() || {};
	const isAlwaysOpen = useFeatureIsOn('is-always-filter-tops');
	const isBrowser = () => typeof window !== 'undefined';
	const virtuosoRef = useRef(null);
	const [algoliaRefreshKey, setAlgoliaRefreshKey] = useState(0);
	const [activeFrame] = useActiveFrame();
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });
	const { refine } = usePagination();
	const { locale } = useRouter();
	const bfItems = getAlgoliaPreSelectedRefinementsBuildFlow(locale);
	const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
	const [priceFilter, setPriceFilter] = useState(null);
	const { items } = useCurrentRefinements();
	const refinements = useMemo(() => items.flatMap(item => item.refinements || []), [items]);
	const filteredRefinements = useMemo(
		() => refinements.filter(refinement => !bfItems.some(item => item.name === refinement.value)),
		[refinements, bfItems]
	);
	const { results } = useHits();
	const { items: chooseTopsItems, refine: refineChooseTopsStep } = useRefinementList({
		attribute: 'tags',
		operator: 'or',
		limit: 50,
	});
	const filteredChooseTopsItems = useMemo(
		() =>
			chooseTopsItems
				.filter(item => bfItems.some(ref => ref.name === item.value))
				.map(item => ({
					...item,
					label: bfItems.find(ref => ref.name === item.value)?.label || item.label,
				})),
		[chooseTopsItems, bfItems]
	);
	useEffect(() => {
		if (!priceFilter) return;
		setAlgoliaRefreshKey(Date.now());
	}, [priceFilter]);

	const [frameShape, setFrameShape] = useState(frameShapeOverride || activeFrame);
	const [isOpen, setIsOpen] = useState(!isMobile && type !== 'bf-all-tops');
	const [showFrameSelector, setShowFrameSelector] = useState(true);
	const [isHeaderBigger, setIsHeaderBigger] = useState(true);

	const { data: collections } = useCollectionsQuery(collectionList, {
		queryKey: 'active-collections',
		type: 'active',
		withProducts: false,
		country,
		language,
		queryRefreshVars: [locale],
	});

	const { items: frameOptions } = useRefinementList({ attribute: 'options.frame' });
	const { query } = useSearchBox();

	useEffect(() => {
		const isValidatedQueryBaseFrame = validateQueryBaseFrame(query);
		setShowFrameSelector(frameOptions.length > 0 && !isValidatedQueryBaseFrame);
	}, [frameOptions, query]);

	const updateFrameShape = useCallback(
		(frameShape: (typeof BASE_FRAME_NAMES)[number]) => {
			setFrameShape(frameShape);
			refine(0);
		},
		[frameShape]
	);

	const scrollToTop = useCallback(() => {
		if (!isBrowser()) return;
		virtuosoRef.current.scrollToIndex({ index: 0, behavior: 'smooth' });
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		if (!setChooseTopsFilter || !setIsOpen) return;

		setChooseTopsFilter({
			callback: setIsOpen,
			value: isOpen,
			filtered: !!filteredRefinements?.length,
			resultNumber: results?.nbHits > 0 ? results?.nbHits : 0,
		});
	}, [setIsOpen, isOpen, items, results, setChooseTopsFilter]);
	useEffect(() => {
		type === 'bf-all-tops' && !isMobile && setIsOpen(isAlwaysOpen);
	}, [isAlwaysOpen, type, isMobile]);
	useEffect(() => {
		if (!setChooseTopsHandler) return;
		refineChooseTopsStep &&
			filteredChooseTopsItems &&
			setChooseTopsHandler({
				refine: refineChooseTopsStep,
				items: filteredChooseTopsItems,
			});
	}, [refineChooseTopsStep, chooseTopsItems]);

	return (
		<FilterContext.Provider
			value={{
				collections,
				frameShape,
				isSunglassesRoute,
				isOpen,
				isMobile,
				type,
				virtuosoRef,
				showFrameSelector,
				setFrameShape,
				setIsOpen,
				scrollToTop,
				updateFrameShape,
				isHeaderBigger,
				setIsHeaderBigger,
				priceFilter,
				setPriceFilter,
				algoliaRefreshKey,
			}}
		>
			{children}
		</FilterContext.Provider>
	);
};

export function useFilterContext() {
	return useContext(FilterContext);
}
