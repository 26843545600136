import { Button, Flex, Title } from '@components';
import styles from '../NoResults/NoResults.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		popularSearches: translator('popular-searches'),
		sunTops: translator('sun-tops'),
		popularSearchSparkle: translator('popular-search-sparkle'),
		baseFrame: translator('base-frame'),
	};
};

const PopularSearches = ({ handleSearch }) => {
	// ! This array should be sourced from Algolia as soon as clean data is available
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const popular = [translations.sunTops, 'Harry Potter', translations.popularSearchSparkle, translations.baseFrame];

	return (
		<div id='popular' className={styles.popular}>
			<Flex column gap={3}>
				<Title>{translations.popularSearches}</Title>
				{popular.map(p => (
					<Button
						key={`search-suggestion-${p}`}
						style={{
							padding: '0 0.8rem',
							textAlign: 'left',
							height: '4rem',
						}}
						fullWidth
						linkStyle
						onClick={() => handleSearch(p)}
					>
						{p}
					</Button>
				))}
			</Flex>
		</div>
	);
};

export default PopularSearches;
