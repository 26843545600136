import { FC, forwardRef } from 'react';
import cn from 'classnames';
import { TypographyProps } from '@ts/components';
import styles from './Caption.module.scss';

/**
 * Caption
 *
 * @sizes
 * - Default
 *   - Size: 1.6rem
 * - Small
 *   - Size: 1.2rem
 * @default
 * - Weight: 500
 * - Height: 150%
 * - Family: var(--poppins)
 * - Margin: 0
 */
const Caption: FC<TypographyProps<HTMLSpanElement>> = forwardRef(
	({ children, className, style, small, color, large, medium, ...props }, forwardedRef) => {
		const fontSize = small ? '1.2rem' : medium ? '1.4rem' : large ? '1.8rem' : '1.6rem';
		return (
			<span
				style={{
					fontSize,
					color,
					...style,
				}}
				ref={forwardedRef}
				className={cn(styles['default'], className)}
				{...props}
			>
				{children}
			</span>
		);
	}
);

Caption.displayName = 'Caption';

export default Caption;
