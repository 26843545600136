import { memo } from 'react';
import { Paragraph, PopularSearches, StyledWordEmphasizer, Title } from '@components';
import { FAQ_URL } from '@constants';
import styles from './NoResults.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		noResultsFoundFor: (query: string) => translator('no-results-found-for', { query }),
		searchTips: translator('search-tips'),
		checkTyposInSearch: translator('check-typos-in-search'),
		useGenericSearchTerms: translator('use-generic-search-terms'),
		productMayNoAvailable: translator('product-may-no-available'),
		contactUsAlternative: (contactUs: string, faq: string) => translator('contact-us-alternative', { contactUs, faq }),
		contactUs: translator('contact-us'),
		faq: translator('pair-care-coverage-text-highlighted-word'),
	};
};

const NoResults = ({ query, handleSearch }) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<>
			<div className={styles.container}>
				<Title style={{ marginBottom: '1.6rem' }}>
					<StyledWordEmphasizer
						text={translations.noResultsFoundFor(query)}
						emphasizedWords={String(query)}
						styledTexts={
							<span>
								{' '}
								<wbr /> <q>{query}</q>{' '}
							</span>
						}
					/>
				</Title>

				<Title style={{ marginBottom: '0.8rem' }}>{translations.searchTips}</Title>
				<ul>
					<li>
						<Paragraph>{translations.checkTyposInSearch}</Paragraph>
					</li>
					<li>
						<Paragraph>{translations.useGenericSearchTerms}</Paragraph>
					</li>
					<li>
						<Paragraph>{translations.productMayNoAvailable}</Paragraph>
					</li>
					<li>
						<StyledWordEmphasizer
							text={translations.contactUsAlternative(translations.contactUs, translations.faq)}
							emphasizedWords={[translations.contactUs, translations.faq]}
							styledTexts={[
								<a key='contact' className='blue-text' href='mailto:hello@paireyewear.com'>
									{translations.contactUs}
								</a>,
								<a key='faq' className='blue-text' href={FAQ_URL}>
									{translations.faq}
								</a>,
							]}
						/>
					</li>
				</ul>
			</div>
			<PopularSearches handleSearch={handleSearch} />
		</>
	);
};

export default memo(NoResults);
