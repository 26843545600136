/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef } from 'react';
import { Flex, Title } from '@components';
import { capitalize } from '@utils/strings';
import { AnyArray } from '@ts/general';
import styles from './AutoCompleteDropdown.module.scss';
import { useTranslation } from '@utils/index';

// NOTE: Stronger typings for Algolia's Autocomplete API
type AutoCompleteDropdownProps = {
	collections: AnyArray;
	autocomplete: any;
	redirectOption?: {
		description: string;
		name: string;
		redirect: string;
	};
};

const getTranslatedTexts = translator => {
	return {
		searchSuggestions: translator('search-suggestions'),
	};
};

const AutoCompleteDropdown = forwardRef<HTMLDivElement, AutoCompleteDropdownProps>(
	({ autocomplete, collections, redirectOption }, ref) => {
		const { translator } = useTranslation();
		const translations = getTranslatedTexts(translator);

		return (
			<Flex column fullWidth className={styles['autocomplete']}>
				<Title>{translations.searchSuggestions}</Title>
				<div ref={ref} className={styles['autocomplete__collections']} {...autocomplete.getPanelProps({})}>
					{!!redirectOption && (
						<li key={redirectOption.name} className={`aa-Item ${styles['autocomplete__redirect']}`}>
							<a href={redirectOption.redirect}>{capitalize(redirectOption.name)}</a>
							<div className={styles['autocomplete__chevron']}>
								<svg width='11' height='10' viewBox='0 0 11 10' fill='none'>
									<path d='M 9.48528 7.82839 L 9.48528 0.757324 M 9.48528 0.757324L 2.41421 0.757324 M 9.48528 0.757324L 1 9.48528' />
								</svg>
							</div>
						</li>
					)}
					{!!collections &&
						collections.map((collection, index) => {
							const { source, items } = collection;
							return (
								<div key={`source-${index}`} className='aa-Source'>
									{items.length > 0 && (
										<ul className='aa-List' {...autocomplete.getListProps()}>
											{items.map(item => {
												const strings = item._highlightResult.query.value
													.replace(/<[^>]*>/g, ' ')
													.split(' '); // Removes <mark> HTML tags
												const remainingPhrase = strings.slice(2).join(' ');

												return (
													<li
														key={item.objectID}
														className={`aa-Item ${styles['autocomplete__item']}`}
														{...autocomplete.getItemProps({
															item,
															source,
														})}
													>
														<b>{strings[1]}</b>
														<span>{remainingPhrase}</span>
													</li>
												);
											})}
										</ul>
									)}
								</div>
							);
						})}
				</div>
			</Flex>
		);
	}
);

AutoCompleteDropdown.displayName = 'AutoCompleteDropdown';

export default AutoCompleteDropdown;
